/*
    C-SKIN
    v0.1.0
    Composant générique CSS ITADS
    https://github.com/ita-design-system/c-skin.scss
*/
// SCSS map
$briks-components-generic: ( 
    // Nom du composant
    skin: ( 
        // Composant activé true ou false
        enabled: true, 
        // Responsive activé true ou false
        responsive: true, 
        // Liste des propriétés c-skin par défaut
        defaults: (
            pseudo-classes: (
                // c-skin:disabled uniquement pour les <button>
                disabled: (
                    pointer-events: none,
                    color: grey,
                    background-color: lightgrey
                )
            )
        ),
        // Rendu: 
        // c-skin {
        // }
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // BACKGROUND
            // c-skin m-bg-0
            bg-0: (
                background: none
            ),
            // c-skin m-bg-none
            bg-none: (
                background: none
            ),
            // BACKGROUND SIZE
            // c-skin m-bsize-cover
            bsize-cover: (
                background-size: cover
            ),
            // c-skin m-bsize-contain
            bsize-contain: (
                background-size: contain
            ),
            // BACKGROUND REPEAT
            // c-skin m-brep-no-repeat
            brep-no-repeat: (
                background-repeat: no-repeat
            ),
            // c-skin m-brep-repeat-x
            brep-repeat-x: (
                background-repeat: repeat-x
            ),
            // c-skin m-brep-repeat-y
            brep-repeat-y: (
                background-repeat: repeat-y
            ),
            // BACKGROUND POSITION
            // c-skin m-bpos-center
            bpos-center: (
                background-position: center
            ),
            // POINTER
            // c-skin m-cur-pointer
            cur-pointer: (
                cursor: pointer
            ),
            // TRANSITION
            // c-skin m-transition-0
            transition-0: (
                transition: none
            ),
            // c-skin m-transition-1
            transition-1: (
                transition: all 300ms
            ),
            // OPACITY
            // c-skin m-opa-0
            opa-0: (
                opacity: 0
            ),
            // c-skin m-opa-1
            opa-1: (
                opacity: 0.1
            ),
            // c-skin m-opa-2
            opa-2: (
                opacity: 0.2
            ),
            // c-skin m-opa-3
            opa-3: (
                opacity: 0.3
            ),
            // c-skin m-opa-4
            opa-4: (
                opacity: 0.4
            ),
            // c-skin m-opa-5
            opa-5: (
                opacity: 0.5
            ),
            // c-skin m-opa-5
            opa-6: (
                opacity: 0.6
            ),
            // c-skin m-opa-7
            opa-7: (
                opacity: 0.7
            ),
            // c-skin m-opa-8
            opa-8: (
                opacity: 0.8
            ),
            // c-skin m-opa-9
            opa-9: (
                opacity: 0.9
            ),
            // c-skin m-opa-10
            opa-10: (
                opacity: 10
            ),
            // c-skin m-ls-none
            ls-none: (
                list-style: none
            ),
            // BORDERS
            // BORDER STYLE
            // c-skin m-bstyle-solid
            bstyle-solid: (
                border-style: solid
            ),
            // c-skin m-btstyle-solid
            btstyle-solid: (
                border-top-style: solid
            ),
            // c-skin m-brstyle-solid
            brstyle-solid: (
                border-right-style: solid
            ),
            // c-skin m-bbstyle-solid
            bbstyle-solid: (
                border-bottom-style: solid
            ),
            // c-skin m-blstyle-solid
            blstyle-solid: (
                border-left-style: solid
            ),
            // BORDER WIDTH
            // c-skin m-bwidth-1
            bwidth-1: (
                border-width: 1px
            ),
            // c-skin m-btwidth-1
            btwidth-1: (
                border-top-width: 1px
            ),
            // c-skin m-brwidth-1
            brwidth-1: (
                border-right-width: 1px
            ),
            // c-skin m-bbwidth-1
            bbwidth-1: (
                border-bottom-width: 1px
            ),
            // c-skin m-blwidth-1
            blwidth-1: (
                border-left-width: 1px
            ),
            // c-skin m-bwidth-2
            bwidth-2: (
                border-width: 2px
            ),
            // c-skin m-btwidth-2
            btwidth-2: (
                border-top-width: 2px
            ),
            // c-skin m-brwidth-2
            brwidth-2: (
                border-right-width: 2px
            ),
            // c-skin m-bbwidth-2
            bbwidth-2: (
                border-bottom-width: 2px
            ),
            // c-skin m-blwidth-2
            blwidth-2: (
                border-left-width: 2px
            ),
            // c-skin m-bwidth-3
            bwidth-3: (
                border-width: 3px
            ),
            // c-skin m-btwidth-3
            btwidth-3: (
                border-top-width: 3px
            ),
            // c-skin m-brwidth-3
            brwidth-3: (
                border-right-width: 3px
            ),
            // c-skin m-bbwidth-3
            bbwidth-3: (
                border-bottom-width: 3px
            ),
            // c-skin m-blwidth-3
            blwidth-3: (
                border-left-width: 3px
            ),
            // c-skin m-b-0
            b-0: (
                border: none
            ),
            // c-skin m-bt-0
            bt-0: (
                border-top: none
            ),
            // c-skin m-br-0
            br-0: (
                border-right: none
            ),
            // c-skin m-bb-0
            bb-0: (
                border-bottom: none
            ),
            // c-skin m-bl-0
            bl-0: (
                border-left: none
            ),
            // BORDER RADII
            // c-skin m-brad-0
            brad-0: (
                border-radius: 0
            ),
            // c-skin m-bradtl-0
            bradtl-0: (
                border-top-left-radius: 0
            ),
            // c-skin m-bradtr-0
            bradtr-0: (
                border-top-right-radius: 0
            ),
            // c-skin m-bradbr-0
            bradbr-0: (
                border-bottom-right-radius: 0
            ),
            // c-skin m-bradbl-0
            bradbl-0: (
                border-bottom-left-radius: 0
            ),
            // c-skin m-c-0
            c-0: (
                color: transparent
            ),
            // c-skin m-bc-0
            bc-0: (
                background-color: transparent
            ),
            // c-skin m-pe-none
            pe-none: (
                pointer-events: none
            ),
            // c-skin m-pe-auto
            pe-auto: (
                pointer-events: auto
            ),
            // c-skin m-disabled
            disabled: (
                pointer-events: none,
                color: grey,
                background-color: lightgrey
            ),
            // c-skin m-appearance-none
            appearance-none: (
                -webkit-appearance: none
            ),
            // c-skin m-bs-0
            bs-0: (
                box-shadow: none
            ),
        )
    )
);